import { Check, Info, X } from "lucide-react";
import { H2, Large, Muted } from "../typography/typography";
import { Plan } from "@/data/data";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";


export function GetContentPlan({ plan, className, hasButton, handleSelectedPlan }: { plan: Plan, className?: string, hasButton: boolean, handleSelectedPlan?: (plan: Plan) => Promise<void> }) {
  return (
    <div className={className}>
      <div className="flex items-center justify-start space-x-2 mb-0 min-h-10">
        <div className="flex flex-row space-y-0">
          <H2 className="bold">{plan.price}</H2>
        </div>
        <div className="flex flex-col space-y-0">
          {plan.priceDescription && <Muted>{plan.priceDescription}</Muted>}
          {plan.priceDescription2 && <Muted>{plan.priceDescription2}</Muted>}
        </div>
      </div>
      {hasButton && handleSelectedPlan && <div className="w-full">
          <div className="mt-4 flex-grow">
          <Button className="w-full min-h-12" onClick={() => handleSelectedPlan(plan)}>{plan.actionTitle}</Button>
        </div>
      </div> }
      <ul className="mt-4 space-y-3">
        {plan.features.map((feature, index) => (
          <PlanFeatureItem
            key={index}
            check={feature.check}
            title={feature.title}
            description={feature.description}
            subDescription={feature.subDescription}
            subDescription2={feature.subDescription2}
            info={feature.info}
          />
        ))}
      </ul>
      <div className="mt-6 flex-grow">
        {plan.feeDescription && <Muted className="w-full">{plan.feeDescription}</Muted>}
      </div>
    </div>
  );
}


interface PlanFeatureItemProps {
    check: boolean;
    title: string;
    description?: string;
    subDescription?: string;
    subDescription2?: string;
    info?: string;
  }
  
  function PlanFeatureItem({ check, title, description, subDescription, subDescription2, info }: PlanFeatureItemProps) {
    return (
      <div className="flex items-start space-x-2 gap-2">
        <div className="min-[24]:">
          {check ? <Check className="mt-0 text-green-500" /> : <X className="mt-0 text-red-500" />}
        </div>
        <div>
          <Large>{title}</Large>
          {description && <Muted>{description}</Muted>}
          {subDescription && <Muted>{subDescription}</Muted>}
          {subDescription2 && <Muted>{subDescription2}</Muted>}
        </div>
        {info && 
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" size='icon' className="rounded-lg text-muted-foreground"><Info/></Button>
          </PopoverTrigger>
          <PopoverContent className="w-80">
            <div className="flex-grow"><Muted>{info}</Muted></div>
          </PopoverContent>
        </Popover>
        }
      </div>
    );
  }
  