import { Plan, Plans } from "./data";
import { CountryCode } from "./regions";

export function GetBundlePlans(group: string, country: string) : Plan[] {  
    return bundlePlan.Plans.filter(x => x.group == group && x.country == country);
}

export function GetBundlePlanType(group: string, type: string, country: string) : Plan[] {  
    return bundlePlan.Plans.filter(x => x.group == group && x.type == type && x.country == country);
}

export function FindPlanByName(name: string) : Plan | undefined {
    return bundlePlan.Plans.find(x => x.publicName == name);
}

export const bundlePlan: Plans = {
    Plans: [
        {
            publicName: "stripe2025usmonthlybasic",
            name: "stripe2025usmonthlybasic",
            country: CountryCode.US,
            interval: "month",
            group: "saas",
            type: "basic",

            default: true,
            recurring: true,
            supportMessages: false,
    
            category: "Monthly",
            title: "Basic",
            subtitle: "Ideal for individuals and small teams",
            price: "$5",
            priceAmount: 500,
            priceCurrency: "usd",
            priceDescription: "per month",
            
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed monthly",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    subDescription: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call history with Hubspot",
                },
            ],
            actionTitle: "Get Started",

            payButton: "Pay $5/month",
        },
        {
            publicName: "stripe2025usyearlybasic",
            name: "stripe2025usyearlybasic",
            country: CountryCode.US,
            interval: "year",
            group: "saas",
            type: "basic",

            recurring: true,
            supportMessages: false,
    
            category: "Annually",
            title: "Basic",
            subtitle: "Ideal for individuals and small teams",
            price: "$4",
            priceAmount: 5000,
            priceCurrency: "usd",
            priceDescription: "per month",
            priceDescription2: "billed annually at $48",
            save: "-20% Off",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed annually",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    subDescription: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call history with Hubspot",
                },
            ],
            actionTitle: "Get Started",

            payButton: "Pay $48/year",
        },
        {
            publicName: "stripe2025usmonthlypro",
            name: "stripe2025usmonthlypro",
            country: CountryCode.US,
            interval: "month",
            group: "saas",
            type: "pro",

            default: true,
            recurring: true,
            supportMessages: true,
    
            category: "Monthly",
            title: "Pro",
            subtitle: "Best for growing businesses and professional teams",
            price: "$18",
            priceAmount: 1800,
            priceCurrency: "usd",
            priceDescription: "per month",
            
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed monthly",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    subDescription: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    subDescription: "to any U.S. phone numbers",
                    info: "To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of $5 and a recurring messaging campaign fee of $1.50/month.",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
            ],

            actionTitle: "Get Started",

            payButton: "Pay $18/month",
        },
        {
            publicName: "stripe2025usyearlypro",
            name: "stripe2025usyearlypro",
            country: CountryCode.US,
            interval: "year",
            group: "saas",
            type: "pro",

            recurring: true,
            supportMessages: true,
    
            category: "Annually",
            title: "Pro",
            subtitle: "Best for growing businesses and professional teams",
            price: "$15",
            priceAmount: 18000,
            priceCurrency: "usd",
            priceDescription: "per month",
            priceDescription2: "billed annually at $180",
            save: "-17% Off",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed annually",
                    subDescription2: "port-in existing (enabled)",
                    
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    subDescription: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    subDescription: "to any U.S. phone numbers",
                    info: "To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of $5 and a recurring messaging campaign fee of $1.50/month."
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
            ],

            actionTitle: "Get Started",

            payButton: "Pay $180/year",
        },
        {
            publicName: "stripe2025camonthlybasic",
            name: "stripe2025camonthlybasic",
            country: CountryCode.CA,
            interval: "month",
            group: "saas",
            type: "basic",

            default: true,
            recurring: true,
            supportMessages: false,
    
            category: "Monthly",
            title: "Basic",
            subtitle: "Ideal for individuals and small teams",
            price: "CA$9",
            priceAmount: 900,
            priceCurrency: "cad",
            priceDescription: "per month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$5/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call history with Hubspot of each user in your Phone2 workspace",
                }
            ],
        
            actionTitle: "Get Started",

            payButton: "Pay CA$9/month",
        },
        {
            publicName: "stripe2025cayearlybasic",
            name: "stripe2025cayearlybasic",
            country: CountryCode.CA,
            interval: "year",
            group: "saas",
            type: "basic",

            recurring: true,
            supportMessages: false,
    
            category: "Annually",
            title: "Basic",
            subtitle: "Ideal for individuals and small teams",
            price: "CA$8",
            priceAmount: 2000,
            priceCurrency: "cad",
            priceDescription: "per month",
            priceDescription2: "billed annually at CA$96",
            save: "-11% Off",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$5/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$10/user/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call history with Hubspot of each user in your Phone2 workspace",
                }
            ],
            actionTitle: "Get Started",

            payButton: "Pay CA$96/year",
        },
        {
            publicName: "stripe2025camonthlypro",
            name: "stripe2025camonthlypro",
            country: CountryCode.CA,
            interval: "month",
            group: "saas",
            type: "pro",

            default: true,
            recurring: true,
            supportMessages: true,
    
            category: "Monthly",
            title: "Pro",
            subtitle: "Best for growing businesses and professional teams",
            price: "CA$24",
            priceAmount: 2400,
            priceCurrency: "cad",
            priceDescription: "per month",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$5/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    description: "to any Canada phone numbers",
                    info: "To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of CA$6.99 and a recurring messaging campaign fee of CA$2.50/month.",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                }
            ],

            actionTitle: "Get Started",

            payButton: "Pay CA$25/month",
        },
        {
            publicName: "stripe2025cayearlypro",
            name: "stripe2025cayearlypro",
            country: CountryCode.CA,
            interval: "year",
            group: "saas",
            type: "pro",

            recurring: true,
            supportMessages: true,
    
            category: "Annually",
            title: "Pro",
            subtitle: "Best for growing businesses and professional teams",
            price: "CA$20",
            priceAmount: 2400,
            priceCurrency: "cad",
            priceDescription: "per month",
            priceDescription2: "billed annually at CA$240",
            save: "-20% Off",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$5/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: CA$10/user/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "any US/Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited sms",
                    description: "to any Canada phone numbers",
                    info:"To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of CA$6.99 and a recurring messaging campaign fee of CA$2.50/month.",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                }
            ],

            actionTitle: "Get Started",

            payButton: "Pay CA$240/year",
        },
        {
            publicName: "stripe2025usmonthlylifetime",
            name: "stripe2025usmonthlylifetime",
            country: CountryCode.US,
            interval: "basic",
            group: "lifetime",
            type: "lifetime",

            recurring: false,
            default: true,
            supportMessages: false,
    
            category: "Basic",
            title: "Lifetime basic",
            subtitle: "Pay once, use forever. No more monthly bills.",
            price: "$99",
            priceAmount: 9900,
            priceCurrency: "usd",
            priceDescription: "one time payment",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed monthly",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "3 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month. Unlimited incoming calls",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: false,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
            ],
            actionTitle: "Get Started",

            payButton: "Pay $99",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            publicName: "stripe2025usmonthlylifetimepro",
            name: "stripe2025usmonthlylifetimepro",
            country: CountryCode.US,
            interval: "pro",
            group: "lifetime",
            type: "lifetime",

            recurring: false,
            supportMessages: true,

            category: "Pro+",
            title: "Lifetime Pro",
            subtitle: "Pay once, use forever. No more monthly bills.",
            price: "$129",
            priceAmount: 12900,
            priceCurrency: "usd",
            priceDescription: "one time payment",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed monthly",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "3 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    description: "incoming and outgoing. ",
                },
                {
                    check: true,
                    title: "900 outgoing sms",
                    description: "per month. Unlimited incoming SMS",
                    subDescription: "Receive 2FA codes 📲",
                    info:"To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of $5 and a recurring messaging campaign fee of $1.50/month.",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot",
                },
            ],
            actionTitle: "Get Started",

            payButton: "Pay $129",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            publicName: "stripe2025camonthlylifetime",
            name: "stripe2025camonthlylifetime",
            country: CountryCode.CA,
            interval: "basic",
            group: "lifetime",
            type: "lifetime",

            recurring: false,
            default: true,
            supportMessages: false,
    
            category: "Basic",
            title: "Lifetime Basic 🇨🇦",
            subtitle: "For users who want to pay only once.",
            price: "CA$169",
            priceAmount: 16900,
            priceCurrency: "cad",
            priceDescription: "one time payment",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$7/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "3 users included",
                    description: "each additional: CA$13/user/mo",
                    subDescription: "Billed monthly",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month, to CA/US numbers. Incoming unlimited calls.",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: false,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                },
            ],
            actionTitle: "Get Started",

            payButton: "CA$169",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            publicName: "stripe2025camonthlylifetimepro",
            name: "stripe2025camonthlylifetimepro",
            country: CountryCode.CA,
            interval: "pro",
            group: "lifetime",
            type: "lifetime",

            recurring: false,
            supportMessages: true,
    
            category: "Pro+",
            title: "Lifetime Pro 🇨🇦",
            subtitle: "For users who want to pay only once.",
            price: "CA$199",
            priceAmount: 19900,
            priceCurrency: "cad",
            priceDescription: "one time payment",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: CA$7/mo",
                },
                {
                    check: true,
                    title: "3 users included",
                    description: "each additional: CA$13/user/mo",
                },
                {
                    check: true,
                    title: "1000 outgoing minutes",
                    description: "per month, to CA/US numbers. Incoming unlimited calls.",
                },
                {
                    check: true,
                    title: "600 outgoing sms",
                    description: "per month to to CA/US numbers. Unlimited incoming SMS.",
                    info: "To send SMS to U.S. wireless numbers, you must complete 10DLC carrier registration. This includes a one-time brand and carrier review fee of CA$6.99 and a recurring messaging campaign fee of CA$2.50/month."
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call & text from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call and message history with Hubspot of each user in your Phone2 workspace",
                },
            ],
            actionTitle: "Get Started",

            payButton: "CA$199",
            payDescription: "One time payment. No recurring charges.",
        },
        {
            publicName: "stripe2025usyearlybasicpromo",
            name: "stripe2025usyearlybasic",
            country: CountryCode.US,
            interval: "year",
            group: "promo_basic_yearly",
            type: "basic",

            recurring: true,
            supportMessages: false,
    
            category: "Annually",
            title: "Basic",
            subtitle: "Ideal for individuals and small teams",
            price: "$4",
            priceAmount: 5000,
            priceCurrency: "usd",
            priceDescription: "per month",
            priceDescription2: "billed annually at $48",
            features: [
                {
                    check: true,
                    title: "1 new local number",
                    description: "additional numbers: $5/mo",
                    subDescription: "Billed annually",
                    subDescription2: "port-in existing (enabled)",
                },
                {
                    check: true,
                    title: "4 users included",
                    description: "each additional: $10/user/mo",
                    subDescription: "Billed annually",
                },
                {
                    check: true,
                    title: "Unlimited minutes",
                    subDescription: "to any U.S. and Canadian phone numbers",
                },
                {
                    check: true,
                    title: "Unlimited incoming SMS",
                    description: "Receive 2FA codes 📲",
                },
                {
                    check: true,
                    title: "Custom voicemail greetings",
                    description: "5 slots free",
                },
                {
                    check: true,
                    title: "iOS, Android and web apps included",
                    description: "Call from mobile or desktop 🖥️",
                },
                {
                    check: true,
                    title: "Hubspot",
                    description: "Sync call history with Hubspot",
                },
            ],
            actionTitle: "Get Started",

            payButton: "Pay $48/year",
        },
    ]
}